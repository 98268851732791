import React, { useState } from 'react';
import {PageContainer,ItemContainer,ItemWrapper,ButtonContainer,Button,Img,TextP,Icon,IconContainer, HiddenDiv1,HiddenDiv2} from './elements'

import char from '../../Images/8.svg'
import char2 from '../../Images/2.svg'
import char3 from '../../Images/3.svg'
import char4 from '../../Images/4.svg'
import char5 from '../../Images/7.svg'
import char6 from '../../Images/8.svg'
import {FaInfoCircle,FaTimes} from 'react-icons/fa'
import Header from '../Header';


const Team = ({isOpenTeam,toggleTeamTrue,toggleTeamFalse,text}) =>{
    const [isOpenDiv1,setIsOpenDiv1] = useState(false)
    const toggleDiv1= () =>{
        setIsOpenDiv1(!isOpenDiv1)
        
      }
      const [isOpenDiv2,setIsOpenDiv2] = useState(false)
      const toggleDiv2= () =>{
          setIsOpenDiv2(!isOpenDiv2)
          
        }


    return(
        <div>
            <Header text = {text}/>
            
            <ButtonContainer>
             <Button isOpenTeam = {isOpenTeam} onClick = {toggleTeamFalse}>Tandartsen</Button>
             <Button isOpenTeam = {!isOpenTeam} onClick = {toggleTeamTrue}>Ons Team</Button>
           </ButtonContainer>
       <PageContainer isOpenTeam = {isOpenTeam}>
           

           <ItemContainer>
              <ItemWrapper>
                   <Img src ={char}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px',textAlign: 'center'}}>Zeynep Yaramis</TextP>
                   <TextP style = {{textAlign:'center'}} >Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 19911300202</TextP>
                   
                   <IconContainer>
                   <Icon>{!isOpenDiv1 ?<FaInfoCircle onClick={toggleDiv1}/>:<FaTimes onClick={toggleDiv1}/> }</Icon>
                   </IconContainer>
                   <HiddenDiv1 isOpenDiv1 = {isOpenDiv1}>
                       <TextP >Ik ben in 2009 afgestuurd aan de ACTA. 
                              Na 7 jaar gewerkt te hebben in een algemene tandartspraktijk wilde ik mij toch richten tot de kindertandheelkunde.
                              Een bijzonder zorggroep waarbij ook speciale aandacht voor nodig is.
                             <br/> Elk kind is weer een unieke uitdaging.<br/> Mijn doel is dat ieder kind blij en trots en natuurlijk pijnvrij de tandartsstoel verlaat.
                              </TextP>
                   </HiddenDiv1>

               </ItemWrapper>
               <ItemWrapper>
                   <Img src ={char2}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>Iffet Yaramis</TextP>
                   <TextP style = {{textAlign:'center'}}>Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 19915572002</TextP>
                   
                   <IconContainer>
                   <Icon>{!isOpenDiv2 ?<FaInfoCircle onClick={toggleDiv2}/>:<FaTimes onClick={toggleDiv2}/> }</Icon>
                    </IconContainer>
                   <HiddenDiv2 isOpenDiv2 = {isOpenDiv2}>
                       <TextP >Ik heb in 2012 mijn tandartsenopleiding voltooid aan de ACTA in Amsterdam. 
                           Snel na mijn afstuderen ben ik aan de slag gegaan als kindertandarts. 
                           Na 5 jaar ervaring opgedaan te hebben in een aantal praktijken,
                            hebben we samen met mijn zus besloten een eigen jeugdtandartspraktijk te beginnen. 
                            Dag in dag uit vind ik het weer heerlijk om met kinderen te werken.<br/> Ons doel voor de toekomst: <br/>
                            Gaatjesvrije gebitten!
                              </TextP>
                   </HiddenDiv2>

               </ItemWrapper>     
           </ItemContainer>
           <ItemContainer>
              <ItemWrapper>
                   <Img src ={char2}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px',textAlign: 'center'}}>Betul Dogan </TextP>
                   <TextP style = {{textAlign:'center'}} >Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 19924445102</TextP>
                   
                 

               </ItemWrapper>
               <ItemWrapper>
                   <Img src ={char5}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>Elif Yemenoglu</TextP>
                   <TextP style = {{textAlign:'center'}}>Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 89921626902</TextP>
                   
                

               </ItemWrapper>     
           </ItemContainer>
           <ItemContainer>
              <ItemWrapper>
                   <Img src ={char3}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px',textAlign: 'center'}}>Fatima Zamri </TextP>
                   <TextP style = {{textAlign:'center'}} >Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 69927999102</TextP>
                   
                

               </ItemWrapper>
               <ItemWrapper>
                   <Img src ={char}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px',textAlign: 'center'}}>Tsarah Resh </TextP>
                   <TextP style = {{textAlign:'center'}} >Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 39065804602</TextP>
                   
                

               </ItemWrapper>
           </ItemContainer>
           <ItemContainer>
              <ItemWrapper>
                   <Img src ={char5}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px',textAlign: 'center'}}>Fereshta Atai</TextP>
                   <TextP style = {{textAlign:'center'}} >Tandarts</TextP>
                   <TextP style = {{textAlign:'center'}}>Bignummer: 49930016702</TextP>
               </ItemWrapper>
               
           </ItemContainer>
           

       </PageContainer>
       
       
       <PageContainer isOpenTeam = {!isOpenTeam}>
           

           <ItemContainer>
        
               <ItemWrapper>
                   <Img src ={char5}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>Corinne de Vries</TextP>
                   <TextP style = {{textAlign:'center'}}>Front-office</TextP>
                   
                

               </ItemWrapper>
               <ItemWrapper>
                   <Img src ={char4}/> 
                   <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>E. Yaramis</TextP>
                   <TextP style = {{textAlign:'center'}}>Praktijkmanager</TextP>
                   
                

               </ItemWrapper>
              
               
           </ItemContainer>
           <ItemContainer>
        
            <ItemWrapper>
            <Img src ={char3}/> 
            <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>C. Zhuang</TextP>
            <TextP style = {{textAlign:'center'}}>Tandartsassistente </TextP>
            
         

            </ItemWrapper>

            <ItemWrapper>
        <Img src ={char2}/> 
        <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>Duygu Ekinci-Beskardes</TextP>
        <TextP style = {{textAlign:'center'}}>Tandartsassistente </TextP>
        
     

        </ItemWrapper>

            </ItemContainer>
            <ItemContainer>
        
            <ItemWrapper>
            <Img src ={char2}/> 
            <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>Nicole Albert</TextP>
            <TextP style = {{textAlign:'center'}}>Tandartsassistente </TextP>
            
         

            </ItemWrapper>

            <ItemWrapper>
        <Img src ={char}/> 
        <TextP style = {{color:'#08bfc0',fontWeight:'bold',fontSize:'19px',letterSpacing:'1px', textAlign : 'center'}}>Svitlana Semenova</TextP>
        <TextP style = {{textAlign:'center'}}>Tandartsassistente </TextP>
        
     

        </ItemWrapper>

            </ItemContainer>
            

       </PageContainer>


       </div>

    )
}

export default Team