import React, { useState } from 'react';
import {SidebarContainer, Icon,CloseIcon,SidebarLink,SidebarMenu,SidebarWrapper,Img, ImgLink,CloseDiv,HiddenDiv} from './elements'
import logo from '../../Images/logo.png'
import {FaAngleDown,FaAngleUp} from 'react-icons/fa'

const Sidebar = ({isOpen,toggle}) =>{

    const [isOpenHidden,setIsOpenHidden] = useState(false)
    const handleChange = () =>{
        setIsOpenHidden(!isOpenHidden)
      }

    return(
        <div>
        <SidebarContainer isOpen = {isOpen}  >
            <ImgLink to = '/'>        
            <Img onClick = {toggle} style = {{cursor:'pointer'}} src = {logo}/>
    
            </ImgLink>

            <Icon onClick = {toggle}>
                <CloseIcon/>
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                <SidebarLink to = '/' onClick = {toggle} >
                    HOME
                </SidebarLink>
                <SidebarLink to = 'missie'  onClick = {toggle}>
                    MISSIE
                </SidebarLink> 
                <SidebarLink to = 'inschrijven' onClick = {toggle} >
                    INSCHRIJVEN
                </SidebarLink> 
                <SidebarLink to = 'team' onClick = {toggle}>
                    TEAM
                </SidebarLink> 
                <SidebarLink to ='behandelen'onClick = {toggle}>
                    BEHANDELEN
                </SidebarLink>
                <SidebarLink to ='locaties'onClick = {toggle}>
                    LOCATIES
                </SidebarLink>   
                
                <SidebarLink to = '#'onClick = {handleChange} > 
                    CONTACT &emsp; {!isOpenHidden ? <FaAngleDown/> : <FaAngleUp/>}
                    
                   
                </SidebarLink> 
                <HiddenDiv isOpenHidden = {isOpenHidden}>
                <SidebarLink to ='contact' onClick = {toggle} style = {{marginBottom:'5%'}}>
                    Contact en Openingstijden
                </SidebarLink>  
                <SidebarLink to ='klachten' onClick = {toggle} style = {{marginBottom:'5%'}}>
                    Klachten
                </SidebarLink>  
                <SidebarLink to ='spoedgevallen' onClick = {toggle} style = {{marginBottom:'5%'}}>
                    Spoedgevallen
                </SidebarLink>  

                </HiddenDiv>
               </SidebarMenu>   
            </SidebarWrapper>
        </SidebarContainer>
        <CloseDiv isOpen = {isOpen} onClick = {toggle} />
        </div>
    )

}

export default Sidebar;