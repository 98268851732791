import styled from "styled-components";


export const PraktijkDiv = styled.div`
    display:flex;
    justify-content:space-around;
    
    @media screen and (max-width: 950px){
        display:block;

}`;

export const ItemDiv = styled.div`
    display:block;
    width:400px;

    padding:30px;
    box-shadow: 1px 1px 14px -3px rgba(0,0,0,0.77);
    
    @media screen and (max-width: 950px){
        margin-top:100px;
        margin-left:10px;
        width:300px;

}`;

export const ItemLine = styled.div`
    display:flex;
    align-items:center;
`;

export const IconDiv = styled.div`
    display:flex;
    color:#009CA6;
    font-size:20px;
`;

export const TextDiv = styled.div`
    display:flex;
    padding-left:10px;

`

export const TextP = styled.p`
    text-align:left;
`;

export const TextH = styled.h1`

`;


export const Button = styled.button`
margin-left:145px;
background:white;
color:#009CA6;
font-size:20px;
padding-left:25px;
padding-right:25px;
padding-top:10px;
padding-bottom:10px;
border: 2px solid #009CA6;
border-radius:30px;
cursor:pointer;

&:hover{
    background: #009CA6;
    color:white;
}

`;

export const Img = styled.img`
width:100%;
`;

