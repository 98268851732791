import styled from 'styled-components'



export const HeaderContainer = styled.div`
display:flex;
padding:10px;
width:100%;
height:50px;
font-size:30px;
margin-bottom:50px;
text-align:center;
color:white;
background: linear-gradient(90deg, rgba(0,156,166,1) 0%, rgba(0,244,233,1) 100%);

justify-content:center;
align-content:content;
flex-direction:column;
box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.46);

`;