import React from 'react'
import {KlachtenContainer,TextH2,TextP} from './elements'
import Header from '../Header'


const Klachten = ({text}) =>{

    return(
        <div>
       <Header text = {text}/>
        
        <KlachtenContainer>

            <TextH2>Vragen of Klachten?</TextH2>
            <TextP>Ondanks de inzet van onze medewerkers kan het gebeuren dat u niet tevreden bent over de behandeling in onze praktijk. Wat kunt u doen?
            <br/>
            <br/>
            1. Blijf er niet mee zitten maar spreek ons erop aan! Dan proberen we er samen uit te komen. U kunt het best even vooraf contact leggen via de balie, zodat de tandarts tijd voor u kan maken.
            <br/>
            2. U kunt ook kijken op  <a href="https://www.allesoverhetgebit.nl">https://www.allesoverhetgebit.nl</a> of uw vraag daar beantwoord kan worden. Of win telefonisch advies in bij het Tandheelkundig Tandheelkundig Informatiepunt (TIP) [LINK NAAR https://www.allesoverhetgebit.nl/contact/], tel. 0900 202 5012.
            <br/>
            3. Is uw klacht nog niet opgelost, dan kunt u uw klacht indienen bij <a href="https://www.knmt.nl/voor-patienten/help-ik-heb-een-klacht">de KNMT</a>, de beroepsvereniging waar deze praktijk bij aangesloten is. Een onafhankelijke klachtenfunctionaris zal dan kosteloos tussen u en ons bemiddelen, met als doel alsnog een oplossing te vinden.
            <br/>
            4. Komt u er ondanks de hulp van de klachtenfunctionaris niet uit, kunt u voor €75 een beroep doen op  <a href="https://www.geschilleninstantiemondzorg.nl">de Geschilleninstantie Mondzorg </a>. Dan wordt uw klacht door een commissie van vijf deskundigen beoordeeld.</TextP>
            <TextH2 >Meer Informatie?</TextH2>
            <TextP>Voor meer informatie over mondzorg, behandelingen en kosten kunt u terecht op <a href="https://www.allesoverhetgebit.nl">https://www.allesoverhetgebit.nl</a>. Vragen kunt u stellen aan het Tandheelkundig Informatiepunt (TIP) [LINK NAAR https://www.allesoverhetgebit.nl/contact/], tel. 0900 202 5012. Beide geven onafhankelijke informatie en zijn opgericht door onze beroepsorganisatie KNMT.</TextP>
        
        </KlachtenContainer>
        </div>
    )

}

export default Klachten