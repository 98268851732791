import styled from 'styled-components'
import {FaTimes} from 'react-icons/fa'
import {  Link } from 'react-router-dom';


export const SidebarContainer = styled.aside`
position: fixed;
z-index:999;
width:350px;
height:100%;
background: white;
display:grid;
transition: 0.3s ease-in-out;
opacity:${({isOpen}) =>(isOpen ? '100%': '0')};
top: ${({isOpen}) =>(isOpen ? '0': '-100%')};

@media screen and (min-width: 950px){
  display:none;
}`;



export const Img = styled.img`
    position:absolute;
    left:30px;
    top:30px;
    width: 70%;
    

`;

export const ImgLink = styled(Link)`
width:200px;
height:100%;
`;

export const CloseIcon = styled(FaTimes)`
color:black;
`;

export const Icon = styled.div`
position:absolute;
top:1.2rem;
right:1.5rem;
background:transparent;
cursor:pointer;
outline:none;
`;

export const SidebarWrapper = styled.div`

background:white;
margin-bottom:400px;

@media screen and (max-width:480px ){
    margin-bottom:calc(100px + 125%);
}
`;

export const SidebarMenu = styled.div`

display:grid;
position:absolute;
grid-template-columns: 1fr;
grid-template-rows: repeat(6,45px);
@media screen and (max-width:480px){
    grid-template-rows:repeat(6,60px);
}
`;

export const SidebarLink = styled(Link)`
margin-left:30px;
color:black;
display:flex;
align-items:center;
justify-content:left;
text-decoration:none;
list-style:none;
font-size:1.5rem;
transition: 0.2s ease-in-out;
cursor:pointer;

&:hover{
    color:#03c9cf;
    transition:0.2s ease-in-out;
}
`;

export const CloseDiv = styled.div`
position: fixed;
z-index:999;
left:350px;
width:100%;
height:100%;
float:left;
background: gray;
transition: 0.3s ease-in-out;
opacity:${({isOpen}) =>(isOpen ? '70%': '0')};
top: ${({isOpen}) =>(isOpen ? '0': '-100%')};

@media screen and (min-width: 950px){
  display:none;
}`;

export const HiddenDiv = styled.div`

display: ${({isOpenHidden}) => (isOpenHidden) ?'block':'none'};
padding-top:5%;
padding-right:12%;
width:100%;
background:none;
box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.2);
`