import styled from 'styled-components'
import {NavLink as Link} from 'react-router-dom'

export const NavbarContainer = styled.div`
    display:block;
    
`;


export const LogoContainer = styled.div`
    display:flex;
    justify-content:space-around;
    margin-top:1%;
    @media screen and (max-width: 950px){

}`;


export const LogoItem = styled.div`
    display:flex;
    width:50%;
    @media screen and (max-width: 950px){
 

}`;


export const ImgContainer = styled.div`

    

`;

export const Img = styled.img`
    width:50%;
    margin-top:2%;
    margin-left:10%;
    @media screen and (max-width: 950px){
  width:175%;

}`;


export const IconItem = styled.div`
    display:flex;
    margin-left:5%;
    @media screen and (max-width: 950px){
  display:none;

}`;
   

export const IconContainer = styled.div`
    display:flex;
    margin-top:10%;
    height:40%;
    align-items:center;
    border: 1px solid #009CA6;
    color: #009CA6;
    border-radius: 5px;
    padding:15px 10px 10px 10px;
    
   
`;

export const TextContainer = styled.div`
    display:block;
    margin-top:15%;
    margin-left:10px;
    
`

export const TextP = styled.p`
    margin-top:0rem;
    margin-bottom:0rem;

`;

export const Icon = styled.div`
    font-size:30px;
`;

export const IconSecond = styled.div`
    font-size:30px;
    margin-left:10px;
    color:#0F507B;
    &:hover{
      color:#009CA6;
    }
`;




export const Nav = styled.nav`
display:flex;
@media screen and (max-width: 950px){
    padding-top:5%;
}`;



export const NavbarMenu = styled.div`
    display:flex;
    font-size:20px;
   
  
    padding-left:5%;
   
align-items: center;
text-align: center;
@media screen and (max-width: 950px){
  display:none;

}`;
    

export const ContactDiv = styled.div`
display:block;

`;

export const ContactHidden = styled.div`
display: ${({isOpen}) => (isOpen) ?'block':'none'};
position:absolute;
padding-top:1%;

text-align:left;


background:white;
box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.2);
width:300px;

`;

export const NavLink = styled(Link)`
    color:#0F507B;
    border:none;
    margin:20px;
    text-decoration:none;
    &:hover{
        color: gray;
}
`;

export const NavLinks = styled(Link)`

  color: black;
  display: flex;
  align-items: center;
  text-decoration: none;
  color:#0F507B;
  height: 100%;
  margin-left:5%;
  cursor: pointer;
  &:hover{
  color: #08bfc0;;
}
  
`;

export const Button = styled.button`

border:none;
border-radius:5px;
background:#0F507B;
color:white;
padding:20px;
cursor:pointer;
font-weight:bold;
font-size:15px;
letter-spacing:1px;
&:hover{
  background: #009CA6;
}


`;

export const IconContainerSecond = styled.div`
    display: flex;

    align-items:center;
    
    margin-left:20%;
    @media screen and (max-width: 950px){
  
  padding-left:40%;

}`;
    

export const IconDivSecond = styled.div`
display:flex;
margin-left:20%;


@media screen and (max-width: 1300px){
  margin-left:2%;
}

@media screen and (max-width: 950px){
  display:none;
}
`;

export const MobileIcon = styled.div`
display:none;

@media screen and (max-width: 950px){
    padding-top:10%;
  display:block;
  font-size:25px;
  padding:2px;
  transform: translate(100%,25%);
  cursor:pointer;
}
`;

