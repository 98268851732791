import React, { useState } from 'react';
import {ContactHidden,MobileIcon,IconSecond,ContactDiv,NavLinks,LogoContainer,Button, NavbarContainer,Nav,NavbarMenu,NavLink, LogoItem, Img,ImgContainer,IconItem,IconContainer,Icon,TextContainer,TextP, IconContainerSecond} from './Elements'
import {FaPhone,FaRegBuilding,FaBars,FaFacebook, FaInstagram} from 'react-icons/fa'


const Navbar = ({logo,toggle}) => {
    const [isOpenContact,setIsOpenContact] = useState(false)

    const onMouseEntered = () =>{
        setIsOpenContact(true)
      }


    const onMouseLeaved = () =>{
        setIsOpenContact(false)
    }

    return (
        <NavbarContainer>

            <LogoContainer >
                <LogoItem >
                    <ImgContainer>
                        <NavLink to ='/'>
                        <Img src= {logo} alt = "Jeugdtandarts zuid logo, Rotterdam Zuid"></Img>
                        </NavLink>
                    </ImgContainer>
               
                </LogoItem>

                <LogoItem>
                    <IconItem>
                        <IconContainer>
                            <Icon>
                                <FaPhone/>
                            </Icon>

                        </IconContainer>
                        <TextContainer>
                            <TextP style = {{color: 'gray',fontWeight: 'bold'}}>
                                
                                010 307 48 65
                            </TextP>

                        </TextContainer>
                    
                    </IconItem>
                    <IconItem>
                        <IconContainer>
                            <Icon>
                                <FaRegBuilding/>
                            </Icon>
                        </IconContainer>
                        <TextContainer>
                            <TextP style = {{color:'gray'}}>
                                Slinge 150, 
                            </TextP>
                            <TextP style = {{color: 'gray',fontWeight: 'bold'}}>
                                Rotterdam  
                            </TextP>
                        </TextContainer>
                    </IconItem>
                    <IconItem>
                        <NavLink to = '/spoedgevallen'>
                        <Button>
                            Spoedgeval
                        </Button>
                        </NavLink>
                    </IconItem>

                </LogoItem>

            </LogoContainer>

            <Nav>
            <MobileIcon onClick = {toggle} >
                    <FaBars/>
                </MobileIcon>
                <NavbarMenu>
                    <NavLink to = '/'>
                        HOME
                    </NavLink>
                    <NavLink to = '/missie'>
                        MISSIE
                    </NavLink>
                    <NavLink to = '/inschrijven'>
                        INSCHRIJVEN
                    </NavLink>
                    <NavLink to = '/team'>
                        TEAM
                    </NavLink>
                    <NavLink to = '/behandelen'>
                        BEHANDELEN
                    </NavLink>
                    <NavLink to = '/locaties'>
                        LOCATIES
                    </NavLink>
                    <ContactDiv onMouseEnter = {onMouseEntered} onMouseLeave = {onMouseLeaved}>                   
                    <NavLink to='#'  > 
                    CONTACT
                    </NavLink>

                     <ContactHidden isOpen = {isOpenContact}  >

                     <NavLinks  onClick = {onMouseLeaved}  to='contact' >
                         <p>Contact en Openingstijden </p> 
                    </NavLinks>
                    <NavLinks onClick = {onMouseLeaved}  to='klachten' >
                    <p>Klachten</p>  
                    </NavLinks>
                    <NavLinks  onClick = {onMouseLeaved} to='spoedgevallen' >
                    <p>Spoedgevallen</p>  
                    </NavLinks>

                    </ContactHidden>
                    </ContactDiv> 

                </NavbarMenu>
                <IconContainerSecond>
                   <p > VOLG ONS:  </p>
                   
                   <IconSecond>
                   <a style = {{color:'inherit'}} href="https://www.instagram.com/jeugdtandartszuid/"> <FaInstagram /></a>
                        
                    </IconSecond>
                    <IconSecond>
                    <a style = {{color:'inherit'}} href="https://www.facebook.com/jeugdtandartszuid/"> <FaFacebook /></a>
                        
                    </IconSecond>
                    </IconContainerSecond>

            </Nav>    
            
            
        </NavbarContainer>
    )
}

export default Navbar
