import React, { useState } from 'react';
import Navbar from './Components/Navbar/index'
import {BrowserRouter as Router,Routes,Navigate,Route} from 'react-router-dom'
import logo from './Images/logo.png'
import logo2 from './Images/logo2.png'
import Contact from './Components/Contact';
import Klachten from './Components/Klachten';
import Spoedgeval from './Components/Spoedgeval';
import Behandelen from './Components/Behandelen';
import Missie from './Components/Missie';
import Team from './Components/Team'
import SignIn from './Components/Inschrijven';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Sidebar from './Components/Sidebar';
import Praktijk from './Components/Praktijk';
import Homepage from './Components/Homepage';
import './App.css';

function App() {
  const [isOpen,setIsOpen] = useState(false)
  const [isOpenTeam,setIsOpenTeam] = useState(false)

  const toggle = () =>{
    setIsOpen(!isOpen)
  }
    
  const toggleTeamTrue = () =>{

    setIsOpenTeam(true)

  }
  
  const toggleTeamFalse = () =>{

    setIsOpenTeam(false)

  }
 

  return (
    <div>
      <Router>
        <ScrollToTop>
        <Navbar logo = {logo} toggle = {toggle}/>
        <Sidebar isOpen = {isOpen} toggle = {toggle} />
        <Routes>
        <Route path="/"  element={<Homepage/>}  exact/>
          
          <Route path="/klachten" element={<Klachten/>} />
          <Route path="/contact" element={<Contact text = 'Contact en openingstijden' />}/>
          <Route path="/spoedgevallen" element={<Spoedgeval text = 'Spoedgeval'/>} />
          <Route path="/behandelen"  element={<Behandelen text = 'Behandelen'/>}/>
          <Route path="/locaties"  element={<Praktijk  text = 'Locaties' logo = {logo} logo2 = {logo2}/>}/>
          <Route path="/missie"element={<Missie  text = 'Missie'/>} />
          <Route path="/team" element={<Team text = 'Team' isOpenTeam = {isOpenTeam} toggleTeamFalse = {toggleTeamFalse} toggleTeamTrue = {toggleTeamTrue}  />} />
          <Route path="/inschrijven" element={ <SignIn text = 'Inschrijven'/>} />
          <Route render={() => <Navigate to={{pathname: "/"}} />} />
        </Routes>
        <Footer/>
        </ScrollToTop>
     </Router>
    </div>
  );
}

export default App;
