import styled from 'styled-components'


export const PageContainer = styled.div`
display: ${({isOpenTeam}) =>(!isOpenTeam ? 'flex': 'none')};
width:100%;
height:100%;
flex-direction:column;
margin-top:5%;
@media screen and (max-width:1030px){
  display: ${({isOpenTeam}) =>(!isOpenTeam ? 'block': 'none')};
      
}

`;

export const ButtonContainer = styled.div`
display:flex;
justify-content:center;
align-content:center;
height:50%;
width:100%;
`;

export const Button = styled.button`
height:50%;
padding:20px;
margin-left:5%;
margin-right:5%;
background:white;
border:none;
cursor: pointer;
color:${({isOpenTeam}) =>(!isOpenTeam ? 'black': 'gray')};
border-bottom: ${({isOpenTeam}) =>(!isOpenTeam ? '5px solid #08bfc0': 'none')};
font-size:20px;
`


export const ItemContainer = styled.div`
display:flex;
justify-content:space-around;
align-content:center;
height:100%;
width:80%;
margin-left:10%;
margin-bottom:5%;
@media screen and (max-width:1030px){
  display: ${({isOpenTeam}) =>(!isOpenTeam ? 'block': 'none')};
 

}


`;

export const ItemWrapper = styled.div`
display:block;
width:30%;

box-shadow: 0px 0px 15px 4px rgba(0,0,0,0.45);
@media screen and (max-width:1030px){
  width:100%;
  margin-top:20%;
}



`;

export const Img = styled.img`
width:125px;
margin-left:33%;

`

export const TextP = styled.p`
width:100%;


`;

export const Icon = styled.div`
cursor: pointer;

&:hover{
  color: gray;
}

`;

export const IconContainer = styled.div`
display: flex;
align-items:center;
text-align:center;
justify-content:center;

`;

export const HiddenDiv1 = styled.div`


display:${({isOpenDiv1}) =>(isOpenDiv1? 'flex': 'none')};
align-items:center;
justify-content:center;

text-align:left;
width:90%;
margin-left:5%;
@media screen and (max-width:1030px){
  width:95%;
  margin-left:3%;
}


`;

export const HiddenDiv2 = styled.div`

display:${({isOpenDiv2}) =>(isOpenDiv2? 'flex': 'none')};
align-items:center;
justify-content:center;

text-align:left;
width:90%;
margin-left:5%;
@media screen and (max-width:1030px){
  width:95%;
  margin-left:3%;
}`;