import styled from 'styled-components'
import {Link} from 'react-router-dom'



export const FooterContainer = styled.div`

display:flex;
background:#4e4f4f;
color:white;
width:100%;
height:100%;
flex-direction:column;
padding-top:50px;
margin-top:100px;
@media screen and (max-width:1030px){      
        display:block;  
    }

`;

export const TextContainer = styled.div`
padding-bottom:50px;
display:flex;
width:100%;
height:85%;
margin-left:50px;
@media screen and (max-width:1030px){      
        display:block;  
    }
`;

export const TextWrapper = styled.div`
display:block;
width:25%;
text-align:left;
@media screen and (max-width:1030px){      
        width:90% ;  
        margin-bottom:15%;
    }

`;



export const BottomContainer = styled.div`
display:flex;
justify-content:space-evenly;
width:100%;
padding-bottom:1%;
padding-top:1%;
background:white;
color:gray;

user-select:none;
@media screen and (max-width:1030px){      
        display:block;  
    }
`; 

export const Img = styled.img`
margin-top:10px;
width:25%;
@media screen and (max-width:1030px){      
    width:90%;
        }
`;

export const BottomText = styled.p`
display:block;
@media screen and (max-width:1030px){      
    
        }


`;

export const BottomTextText = styled.p`
display:block;
@media screen and (max-width:1030px){      
        text-align:center;
        }


`;

export const Button = styled.button`
font-size:20px;
padding:20px;
width: calc(100% - 70px);
border:none;
border-radius:5px;
background:white;

cursor:pointer;
&:hover{
    color:white;
  background: #009CA6;
}  
`;

export const StyledLink = styled(Link)`
color:white;
text-decoration:none;
&:hover{
  color: #dbdbdb;
} 

`;

export const Datediv = styled.div`
display:flex;
`;

export const Timediv = styled.div`
display:block;
margin-left:5%;
`;

export const Weekdiv = styled.div`
display:block;
`;
