import React from 'react'
import {ContactContainer, ContactWrapper, TextH1, TextP,Icon,IconContainer,ItemContainer,Item,IFrame} from './IndexElements'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import Header from '../Header'


const Contact = ({text}) =>{

    return(
            <div>
               <Header text = {text}/>
            <ContactContainer>
                <ContactWrapper style = {{marginBottom:'8%'}}>
                    <ItemContainer>
                        <TextH1>
                            Contact
                        </TextH1>
                        <Item>

                
                            <IconContainer>
                                <Icon>
                                    <HiOutlineLocationMarker/>
                                </Icon>
                            </IconContainer>
                            
                            <TextP>Slinge 150 <br/> 3085 EW Rotterdam</TextP>
                

                        </Item>

                        <Item>

                
                            <IconContainer>
                                <Icon>
                                    <FiPhoneCall/>
                                </Icon>
                            </IconContainer>

                            <TextP >010 307 48 65</TextP>


                        </Item>
                        <Item>

                
                            <IconContainer>
                                <Icon>
                                    <AiOutlineMail/>
                                </Icon>
                            </IconContainer>

                            <TextP>info@jeugdtandartszuid.nl</TextP>


                        </Item>
                        
                    </ItemContainer>

                    <ItemContainer>
                    <TextH1>
                        Openingstijden
                     </TextH1>
                     <Item>Maandag:&nbsp;&nbsp; &emsp; 9.00 – 16.30
                    </Item> 
                    <Item>Dinsdag: &nbsp;&nbsp;&nbsp;&nbsp;&emsp; 9.00 – 16.30
                    </Item> 
                    <Item>Woensdag:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  9.00 – 16.30
                    </Item> 
                    <Item>Donderdag:&emsp;  9.00 – 16.30
                    </Item> 
                    <Item>Vrijdag: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &emsp;9.00 – 16.30
                    </Item>   
                     </ItemContainer>

                     <ItemContainer>
                    <TextH1>
                        Gesloten dagen
                     </TextH1>
                     <Item >- &ensp;Nieuwjaarsdag: Zaterdag 1 januari 2022
                    </Item> 
                    <Item >- &ensp;Goede vrijdag: Vrijdag 15 april 2022
                    </Item> 
                    <Item>- &ensp;Pasen Zondag 17 april 2022
                    </Item> 
                    <Item >-&ensp; Koningsdag: Woensdag 27 april 2022
                    </Item> 
                    <Item >- &ensp;Bevrijdingsdag: Donderdag 5 mei 2022
                    </Item>  
                    <Item >- &ensp;Hemelvaartsdag: Donderdag 26 mei 2022
                    </Item> 
                    <Item >- &ensp;Pinksteren  maandag 5 juni 2022
                    </Item>
                     </ItemContainer>
                </ContactWrapper >


              
                <ContactWrapper>
    
                   
                <IFrame src="https://maps.google.com/maps?q=3085%20EW,%20Rotterdam&t=&z=13&ie=UTF8&iwloc=&output=embed"></IFrame>
                
               </ContactWrapper>
                


            </ContactContainer>
            </div>
    )

}

export default Contact