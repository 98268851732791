import React from 'react'
import { PraktijkDiv,ItemDiv,ItemLine,IconDiv,TextDiv,TextP, Button, TextH, Img } from './elements'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import Header from '../Header'


const Praktijk = ({text,logo,logo2}) => {
    return (
        <div>
            <Header text = {text}/>
        <PraktijkDiv>
            
            <ItemDiv>
                <ItemLine>
                
                    <Img alt = 'Logo Jeugd tandarts Zuid' src = {logo}></Img>
                    
                </ItemLine>
                <ItemLine>

                </ItemLine>
                <ItemLine>
                    <IconDiv>
                        <HiOutlineLocationMarker/>
                    </IconDiv>
                    <TextDiv>
                        <TextP>
                            Slinge 150, 3085 EW Rotterdam
                        </TextP>
                    </TextDiv>
                </ItemLine>
                <ItemLine>
                    <IconDiv>
                        <FiPhoneCall/>
                    </IconDiv>
                    <TextDiv>
                        <TextP >
                        010 307 48 65
                        </TextP>
                    </TextDiv>
                </ItemLine>
                <ItemLine>
                    <IconDiv>
                        <AiOutlineMail/>
                    </IconDiv>
                    <TextDiv>
                        <TextP>
                        info@jeugdtandartszuid.nl
                        </TextP>
                    </TextDiv>
                </ItemLine>
                <ItemLine>
                <a style = {{color:'inherit'}} href="https://www.jeugdtandartszuid.nl"> <Button>Maps</Button></a>
                </ItemLine>

            </ItemDiv>

            <ItemDiv>
            <ItemLine>
                
                <Img alt = 'Logo Jeugd tandarts Schiedam' src = {logo2}></Img>
                
            </ItemLine>
                <ItemLine>
                
                </ItemLine>
                <ItemLine>
                    <IconDiv>
                        <HiOutlineLocationMarker/>
                    </IconDiv>
                    <TextDiv>
                        <TextP>
                        Westvest 14, 3111 BV Schiedam
                        </TextP>
                    </TextDiv>
                </ItemLine>
                <ItemLine>
                    <IconDiv>
                        <FiPhoneCall/>
                    </IconDiv>
                    <TextDiv>
                        <TextP>
                            010 304 82 70
                        </TextP>
                    </TextDiv>
                </ItemLine>
                <ItemLine>
                    <IconDiv>
                        <AiOutlineMail/>
                    </IconDiv>
                    <TextDiv>
                        <TextP>
                        info@jtschiedam.nl
                        </TextP>
                    </TextDiv>
                </ItemLine>
                <ItemLine>
                <a style = {{color:'inherit'}} href="https://www.jeugdtandartsschiedam.nl/"> <Button>Website</Button></a>
                </ItemLine>

            </ItemDiv>

            
        </PraktijkDiv>
        </div>
    )
}

export default Praktijk
