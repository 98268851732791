import styled from 'styled-components'

export const SpoedgevalContainer  = styled.div`
display: block;
text-align:left;
margin-left:10%;
width:80%;
@media screen and (max-width: 950px){
    margin-left:3%;
  width:90%
}
`


export const TextH2 = styled.h2`
color:#08bfc0;

`

export const TextP = styled.p`
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

`