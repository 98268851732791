import {React,useState} from 'react'
import {Link} from 'react-router-dom'
import {FaPen,FaRegBuilding,FaInfo,FaGenderless} from 'react-icons/fa'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {Homediv,SpoedDiv,Form,Submit,OpDiv,ClipDiv,TextContainer,InputText,MainContentText,TextH1,TextP2,Pagediv,Img,TextP,Button,ButtonDiv, BehandelenDiv, BehandelenItem, TextH, ButtonBehandel, ImgDiv, MainContentDiv, MainContentImageWrapper, MainContentImage, MainContentImageWrapperSecond, MainContentImageSecond, Smalldiv, ContactDiv, TextArea, FormSecond, FormWrapper, MobileMainContentImageWrapper} from './elements'
import img1 from '../../Images/dental1.png'
import img2 from '../../Images/dental2.png'
import img3 from '../../Images/dental3.png'
import img4 from '../../Images/jt1.jpg'
import img5 from '../../Images/mainf1.jpeg'
import { ItemContainer,Item,IconContainer,Icon } from '../Contact/IndexElements'
import { useForm } from "react-hook-form";
import ClipLoader from "react-spinners/ClipLoader";

// const images = [img5,img6]

const Homepage = () => {

   
    let [loading, setLoading] = useState(false);
    
    const [sent,setSent] = useState(false)

    const { register, handleSubmit, formState: { errors } } = useForm();

    function refreshPage() {
        window.location.reload(false);
      }
    // const [counter,setCounter] = useState(0);


    // setInterval(function(){
 
    //     counter === images.length-1 ? setCounter(0) : setCounter(counter + 1);
        
    //      }, 5000);
    const onSubmit = async(data,e) =>{

        e.preventDefault();
        setLoading(true)
        await fetch("http://localhost:8080/sendsecond", {
        method: "POST",
        mode:'cors',
        headers: {
            "Content-type": "application/json",
            'Access-Control-Allow-Origin':'*'
     },
     body: JSON.stringify({data}),
   })
     .then((res) => res.json()).then(async (res) => {
        const resData = await res;
        if (resData.status === "Email sent") {
            setLoading(false)
            setSent(true)
            alert('U bent ingeschreven')
            refreshPage()
        }
      })    


 };

    return (
        <Pagediv>
            <Homediv>
                <SpoedDiv>
                    <TextP style ={{marginLeft:"11%",width:"100%"}}>Wij nemen nieuwe patiënten aan.<br/> Schrijf nu uw kind in via de website.
                
                    </TextP>
                </SpoedDiv>   
                <Smalldiv>
                    <h1>Welkom in onze praktijk</h1>
                </Smalldiv>
            </Homediv>

            <ButtonDiv>
                <Link to = '/inschrijven'>
                    <Button>
                        <FaPen/> &nbsp;
                        Inschrijven
                    </Button>
                </Link>
                <Link to = '/locaties'>
                <Button>
                    <FaRegBuilding/> &nbsp;
                    Locaties
                </Button>
                </Link>
                <Link to = '/missie'>
                <Button>
                    <FaInfo/> &nbsp;
                    Missie
                </Button>
                </Link>
            </ButtonDiv>
            {/* <BehandelenDiv>
                <BehandelenItem>
                    <ImgDiv>
                        <Img alt = "Jeugd tandarts Rotterdam zuid" src = {img3}></Img>
                    </ImgDiv>
                
                    <TextH>
                        Vullingen
                    </TextH>
                    <TextP>
                        Schoonmaking van de geinfecteerde gedeelte van een tand en opvulling.
                        
                    </TextP>
                    <Link to = '/behandelen'>
                    <ButtonBehandel >Lees meer</ButtonBehandel>
                    </Link>
                </BehandelenItem>

                <BehandelenItem>
                    <ImgDiv>
                        <Img style = {{marginLeft: '130px'}} alt = "Jeugd tandarts Rotterdam zuid" src = {img2}></Img>
                    </ImgDiv>
                
                    <TextH>
                        Rvs kroon
                    </TextH>
                    <TextP>
                        Soms lukt het niet om een gaatje te vullen. Rvs kroon biedt hiervoor een oplossing.
                    </TextP>
                    <Link to = '/behandelen'>
                    <ButtonBehandel>Lees meer</ButtonBehandel>
                    </Link>
                </BehandelenItem>
                <BehandelenItem>
                    <ImgDiv>
                        <Img alt = "Jeugd tandarts Rotterdam zuid" src = {img1}></Img>
                    </ImgDiv>
                
                    <TextH>
                        Preventieve behandelingen
                    </TextH>
                    <TextP>
                        Preventieve behandelingen zijn soms noodzakelijk om gaatjes te voorkomen.
                    </TextP>
                    <Link to = 'behandelen'>
                    <ButtonBehandel>Lees meer</ButtonBehandel>
                    </Link>
                </BehandelenItem>
            </BehandelenDiv> */}

            <MainContentDiv style ={{background : '#e9e9e9'}}>

                <MainContentText style = {{paddingLeft:'13%'}}>
                    <TextH style = {{fontSize : '40px'}}>Welkom in onze praktijk</TextH>
                    <TextP2>
                    Welkom bij Jeugdtandarts zuid, dé kindertandarts voor kinderen van 1 tot 18 jaar. 
                    Wij zijn een team van enthousiaste en gespecialiseerde tandartsen die zich volledig richten op de behandeling en verzorging van kindergebitten. 
                   <br/>
                   <br/>
                    Bij ons staat een vriendelijke en ontspannen sfeer centraal, waarbij we kinderen op hun gemak stellen en zorgen voor een pijnloze behandeling. 
                    <br/>
                   <br/>
                    Bij ons ben je verzekerd van de beste zorg op kindermaat.
                    Kom gerust langs voor een kennismaking en ontdek wat wij voor jouw kind kunnen betekenen.
                    </TextP2>
                  
                </MainContentText>
                 <MainContentImageWrapperSecond>
                    <MainContentImageSecond alt = 'Jeugd tandarts Zuid' src = {img5}></MainContentImageSecond>
                </MainContentImageWrapperSecond>
            </MainContentDiv>

            <MainContentDiv>
            <MainContentImageWrapper>
                    <MainContentImage alt = 'Jeugd tandarts Zuid' src = {img4} style = {{marginTop:'30px'}}></MainContentImage>
            </MainContentImageWrapper>
                <MainContentText>
                    <TextH style = {{fontSize : '40px',margin:'0px'}}>Elk kind een gezond gebit!</TextH>
                    <TextP2>
                    Een gezond melkgebit is belangrijk voor een gezond blijvend gebit, 
                    daarom is het van belang om de tandarts al te bezoeken op een vroege leeftijd. 
                    Kinderen krijgen ruim de tijd en de aandacht. 
                    Wij vinden het heel belangrijk dat uw kind een tandartsbezoek prettig zal ervaren. 
                    <br/><br/>
                    De kinderen worden op een kindvriendelijke manier gewend aan de tandartsbezoeken. 
                    Voor angstige kinderen hebben we wentrajecten, waarbij we alles uitleggen en laten zien (tell-show- do methode).
                    De peuters leren op speelse wijze kennis te maken met de tandarts...
                    <br/><br/>
                    </TextP2>
                    <Link to ='/missie'>
                    <ButtonBehandel>Lees meer</ButtonBehandel>
                    </Link>
                    <MobileMainContentImageWrapper>
                        <MainContentImage alt = 'Jeugd tandarts Zuid' src = {img4}></MainContentImage>
                    </MobileMainContentImageWrapper>
                </MainContentText>
               
            </MainContentDiv>
            <ContactDiv>
            <ItemContainer style = {{display:'block',marginLeft:'3%'}} >
                        <TextH1>
                            Jeugd Tandarts Zuid
                        </TextH1>
                        <Item style = {{width:'60%'}}>
                            <IconContainer style = {{color:'white'}}>
                                <Icon >
                                    <HiOutlineLocationMarker/>
                                </Icon>
                            </IconContainer>  
                            <TextP style = {{color:'white'}}>Slinge 150 <br/> 3085 EW Rotterdam</TextP>
                        </Item>

                        <Item style = {{width:'60%'}}>

                
                            <IconContainer style = {{color:'white'}}>
                                <Icon>
                                    <FiPhoneCall/>
                                </Icon>
                            </IconContainer>

                            <TextP style = {{color:'white'}}>010 307 48 65</TextP>


                        </Item>
                        <Item style = {{width:'60%'}}>

                
                            <IconContainer style = {{color:'white'}}>
                                <Icon>
                                    <AiOutlineMail/>
                                </Icon>
                            </IconContainer>

                            <TextP style = {{color:'white'}}>info@jeugdtandartszuid.nl</TextP>


                        </Item>
                        <Item style = {{width:'60%'}}>

                
                            <IconContainer style = {{color:'white'}}>
                                <Icon>
                                    <FaGenderless/>
                                </Icon>
                            </IconContainer>

                            <TextP style = {{color:'white'}}>0-18 Jaar</TextP>


                        </Item>
                        

                        
                        
                    </ItemContainer>

                    <FormWrapper>
                    <Form onSubmit={handleSubmit(onSubmit)}> 
                    <TextH1 style = {{color:'darkgray'}}>Schrijf naar ons</TextH1>
        
                    <TextP style={{color:'black'}}>Excuses voor de ongemak. De onderstaande contactformulier werkt op dit moment niet. U kunt uw mail sturen naar    
                    <a href="mailto:info@jeugdtandartszuid.nl?"> info@jeugdtandartszuid.nl</a> met uw gewenste opmerking. </TextP>
        
        <TextContainer style = {{color:'gray'}}>
            <p>Naam*</p> &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;
            {errors.Naam && <p style = {{color:'red'}} >{errors.Naam.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Naam" {...register("Naam", {required: "Dit veld is verplicht.", maxLength: 80, message:"Te veel letters"})} />
        

        <TextContainer style = {{color:'gray'}}>
            <p>Achternaam*</p>  &emsp; &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
            {errors.Achternaam && <p style = {{color:'red'}}>{errors.Achternaam.message}</p>}
        </TextContainer>
        <InputText type="text" placeholder="Achternaam" {...register("Achternaam", {required: "Dit veld is verplicht.", maxLength: 100})} />


        <TextContainer style = {{color:'gray'}}>
            <p>Email</p>  &emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp; &emsp;&emsp;
            {errors.Email && <p style = {{color:'red'}}>Er is een fout</p>}
        </TextContainer>
        <InputText type="text" placeholder="Email" {...register("Email", { pattern: /^\S+@\S+$/i})} />


    </Form >
    <FormSecond onSubmit={handleSubmit(onSubmit)}>
        <OpDiv > 
    <TextContainer style = {{color:'gray',marginTop:'30%'}} >
            <p >Opmerking</p>
            
        </TextContainer>
    <TextArea  {...register("Opmerking", {})} />
    </OpDiv>
   
        <Submit  type="submit" />
        <ClipDiv>
        {loading && <ClipLoader />}
        </ClipDiv>
       

    </FormSecond>
    </FormWrapper>

            </ContactDiv>


        </Pagediv>
    )
}

export default Homepage
