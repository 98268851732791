import styled from 'styled-components'
import img from '../../Images/background.jpeg'


export const Pagediv = styled.div`
    display:block;
`;

export const Homediv = styled.div`
    display:flex;
    color:white;
    flex-direction:column;
    background-image:url(${img});
    height:400px;
    background-position:center;

`;

export const SpoedDiv = styled.div`
display:flex;
background: red;
width:25%;
height:40%;
margin-top:5%;
margin-left:5%;
background:rgba(237, 237, 237, 0.5);
border-bottom-left-radius:50px;
border-top-right-radius:50px;
font-family: 'Cairo', sans-serif;
font-weight:bold;
font-size:20px;
@media screen and (max-width:1030px){
    display:block;  
    width:80%;
    margin-top:10%;

    
    }

`;

export const TextH1 = styled.h1`
    font-size:40px;

`;

export const TextP = styled.p`
width:70%;
margin-left:15%;
`;

export const TextP2 = styled.p`
font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
text-align:left;
`;


export const ButtonDiv = styled.div`
    display:flex;
    margin-left:17%;
    padding-top:3%;
    padding-bottom:3%;
    @media screen and (max-width:1030px){
    display:block;  
    
    }
    

`;

export const Button = styled.button`    
    margin-left:30px;
    padding-right:10px;
    padding-left:10px;
    padding-top:30px;
    padding-bottom:30px;
    width:300px;
    border:2px solid #009CA6;
    background:white;
    color:#0F507B;
    cursor:pointer;
    font-size:20px;
    border-radius:3px;
    &:hover{
        background:#009CA6;
        color:white;
    } @media screen and (max-width:1030px){
    margin: 1%; 
    
    }
`;

export const BehandelenDiv = styled.div`
    display:flex;
    padding-left:13%;
    padding-top:4%;
    padding-bottom:10%;
    
    @media screen and (max-width:1030px){
        display:block;
    
    }
`;

export const BehandelenItem = styled.div`
    display:block;
    padding-top:2%;
    padding-bottom:2%;
    box-shadow: 0px 0px 15px -4px #000000;
    border-radius:10px;
    align-items:center;
    text-align:center;
    width:330px;
    margin-left:3%;
    @media screen and (max-width:1030px){
        margin:4%;
    
    }

`;

export const TextH = styled.h1`
    font-size:25px;

`;

export const ButtonBehandel = styled.button`
padding:15px;
background:#009CA6;
color:white;
cursor:pointer;
border:none;
border-radius:3px;
letter-spacing:1px;
font-size:15px;
font-weight:bold;
`;

export const ImgDiv = styled.div`
  width: 100px;
  height: 100px;
  

`;

export const Img = styled.img`
  margin-left:115px;
  height: 100%;
  width: auto;

`;

export const MainContentDiv = styled.div`
    display:flex;
    
    padding:50px;
    @media screen and (max-width:1030px){
        display:block;
        padding:10px;
        padding-bottom:50px;
    
    }
`;

export const MainContentText = styled.div`
    width:40%;
    font-size:19px;
    padding-left:15%;
    @media screen and (max-width:1030px){
        width:90%;
        padding-left:10%
    }
`;

export const MainContentImageWrapper = styled.div`
width:500px;


@media screen and (max-width:1030px){    
       display:none;
}
`;

export const MobileMainContentImageWrapper = styled.div`
display:none;


@media screen and (max-width:1030px){    
    display:block;
       margin-top:3%;
       width:350px;
       height:500px;
}
`;


export const MainContentImageWrapperSecond = styled.div`
width:1000px;

@media screen and (max-width:1030px){    
       margin-top:3%;
       margin-left:3%;
       width:90%;
       height:200px;
}
`;

export const MainContentImageSecond = styled.img`
    width:100%;
    height:auto;
    border-radius:10px;
    box-shadow:7px 5px 20px 0px rgba(0,0,0,0.6);
    margin-left:3%;
    margin-top:3%;
    float:left;
    @media screen and (max-width:1030px){
    width:100%;   
    
    }
`;


export const MainContentImage = styled.img`
    width:75%;
    height:auto;
    border-radius:10px;
    box-shadow:7px 5px 20px 0px rgba(0,0,0,0.6);
    margin-left:36%;
    float:left;
    @media screen and (max-width:1030px){
    width:100%;   
    margin-left:0%;
    
    }
`;


export const Smalldiv = styled.div`
    background:#009CA6;
    position:relative;
    padding-left:1%;
    width :35%;
    top:79px;

    opacity:0.9;
    @media screen and (max-width:1030px){
    width:99%;   
    font-size:14px;
    top:80px;
    
    }
`;

export const ContactDiv = styled.div`
background: linear-gradient(0deg, rgba(0,156,166,1) 0%, rgba(16,189,202,1) 100%);
margin-top:3%;
display:flex;
padding-bottom:10px;
width:100%;
color:white;
@media screen and (max-width:1030px){
        display:block;
    
    }
`;

export const Form = styled.form`

width:100%;
padding:30px;
@media screen and (max-width:1030px){
        display:block;
        width:100%;
        margin-left:0%;
    
    }

`;

export const FormSecond = styled.form`

width:100%;
padding:30px;
 @media screen and (max-width:1030px){
        display:block;
        width:100%;
    
    }
`;

export const InputText = styled.input`
    width:90%;
    padding:10px;
    margin-top:10px;
    float:left;
    @media screen and (max-width:1030px){
        width:80%;
    
    }
    
`;

export const TextArea =styled.textarea`


    width:95%;
    height:150px;
    @media screen and (max-width:1030px){
        width:80%;
    
    }
 
`;

export const Submit = styled.input`
width:50%;
float:left;
padding:10px;
margin-top:14%;
font-weight:bold;
font-size:15px;
border-radius:5px;
background:#009CA6;
border:none;
color:white;
cursor:pointer;
@media screen and (max-width:1030px){
        width:90%;
    
    }
`;

export const TextContainer = styled.div`
display:flex;
float:left;
width:90%;
`;


export const FormWrapper = styled.div`
display:flex;
background:white; 
width:50%; 
margin-left:20%;
border-bottom-left-radius:75px;
border-bottom-right-radius:75px;
@media screen and (max-width:1030px){
        display:block;
        margin-left:2%;
        width:95%;
    
    }

`;

export const ClipDiv = styled.div`

float:right;
margin-right:5%;


@media screen and (max-width:1030px){
    float:none;
    
  
}

`;

export const OpDiv = styled.div`
    margin-top:33%;
    @media screen and (max-width:1030px){
    margin-top:-50%;
    }
`;






