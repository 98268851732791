import React from 'react'
import {FooterContainer,BottomTextText,TextContainer, BottomContainer, TextWrapper,Img,BottomText,Button,StyledLink,Datediv,Timediv,Weekdiv} from './elements'
import logo from '../../Images/logo.png'
import {AiOutlineCopyright} from 'react-icons/ai'
import {FiPhoneCall} from 'react-icons/fi'
import {AiOutlineMail} from 'react-icons/ai'
import {HiOutlineLocationMarker} from 'react-icons/hi'
import {FaPen} from 'react-icons/fa'
import {Link} from 'react-router-dom'


const Footer = () =>{


    return (

        <FooterContainer>
            
            <TextContainer>
                <TextWrapper>
                <BottomText style = {{fontWeight:'bold'}} >
                    Navigatie

                </BottomText>   
               
                <BottomText>
                    <StyledLink to = 'behandelen' >
                    Behandelen
                    </StyledLink>
                    <br/>
                    <StyledLink to = 'spoedgevallen' >
                    Spoedgevallen
                    <br/>
                    </StyledLink>
                    <StyledLink to = 'klachten'>
                        Klachten
                    </StyledLink>
                </BottomText>    
               

            
                </TextWrapper>
               

               
                <TextWrapper>
                <BottomText style = {{fontWeight:'bold'}}>
                    Openingstijden
                    
                </BottomText>
                <Datediv>
                    <Weekdiv>
                    <BottomText>
                Maandag: 
                <br/>
                Dinsdag: 
                <br/>
                Woensdag: 
                <br/>
                Donderdag: 
                <br/>
                Vrijdag:
                <br/>
                </BottomText>
                    </Weekdiv>
                    <Timediv>
                    <BottomText>
                    9.00 – 16.30
                <br/>
                9.00 – 16.30
                <br/>
                9.00 – 16.30
                <br/>
                9.00 – 16.30
                <br/>
                9.00 – 16.30
                <br/>
                </BottomText>

                    </Timediv>
                
                </Datediv>
                </TextWrapper>


                    
                <TextWrapper>
                    <BottomText style = {{fontWeight:'bold'}}>
                        Contact
                    </BottomText>
                    <BottomText> 
                        <HiOutlineLocationMarker/> &emsp; Slinge 150 <br/>   &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3085 EW Rotterdam <br/> &emsp;&emsp; &nbsp;Nederland <br/>
                        <br/>
                        <FiPhoneCall/>  &emsp;  010 307 48 65 <br/>
                        <br/>
                        <AiOutlineMail/> &emsp; info@jeugdtandartszuid.nl
                        

                    </BottomText>
                </TextWrapper>
                
                <TextWrapper>
                    <BottomText style = {{fontWeight:'bold'}}>
                        Inschrijven
                    </BottomText>
                    <BottomText>
                        Door op de onderstaande knop te klikken,<br/> kunt u zich nu inschrijven.
                    </BottomText>
                    <Link to = 'inschrijven' onClick = {() => {
  window.scrollTo(0, 0)
}}>
                        <Button> <FaPen/>&nbsp; Inschrijven</Button>
                    </Link>
                </TextWrapper>

            </TextContainer>
            <BottomContainer >
                
                <Img src = {logo}></Img>
                <BottomTextText>Jeugd Tandarts Zuid</BottomTextText>
                <BottomTextText>Copyright 2021. <AiOutlineCopyright/> Alle rechten voorbehouden.</BottomTextText>

            </BottomContainer>
        </FooterContainer>
        

    )
}

export default Footer